import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from "../../Assets/Images/logo-no-background.svg"
import "./Header.scss"

const Header = () => {
    const [scroll, setScroll] = useState(false)
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50)
        })
    }, [])
    return (
        <header className={scroll ? "main-header scrolled" : "main-header"}>
            <Navbar collapseOnSelect expand="lg" className="p-0">
                <Container>
                    <div className='custom-nav pt-md-2 pt-2 pb-3 mt-md-2 w-100 d-lg-flex align-items-center'>
                        <Navbar.Brand href="/"><img src={Logo} alt="Logo" /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ms-auto">
                                <Nav.Link href="/" className='ms-md-4'>Home</Nav.Link>
                                <Nav.Link target='_blank' href="https://docs.fyda.fi" className='ms-md-4'>Docs</Nav.Link>
                                <Nav.Link target='_blank' href="https://zealy.io/c/fydafinance" className='ms-md-4'>Quests</Nav.Link>
                                <Nav.Link target='_blank' href="https://simulation.fyda.fi" className='ms-md-4'>Simulation</Nav.Link>
                            </Nav>
                            <Nav className='align-items-center flex-row inner-custom-nav ms-auto'>
                                <Nav.Link href="https://github.com/Fyda-Finance" target='_blank' className='custom-git'>
                                    <span>
                                        <span className='d-md-none d-inline-block'>Github</span>
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0108 7C12.8161 7 7 12.7291 7 19.8169C7 25.4825 10.7266 30.2783 15.8964 31.9757C16.5427 32.1033 16.7795 31.6999 16.7795 31.3606C16.7795 31.0634 16.7582 30.045 16.7582 28.9838C13.1389 29.7478 12.3852 27.4559 12.3852 27.4559C11.8036 25.9705 10.9418 25.5887 10.9418 25.5887C9.7572 24.8036 11.0281 24.8036 11.0281 24.8036C12.3421 24.8885 13.0316 26.1192 13.0316 26.1192C14.1946 28.0713 16.0687 27.5197 16.8226 27.1801C16.9302 26.3525 17.2751 25.7796 17.6413 25.4614C14.7547 25.1642 11.7176 24.0609 11.7176 19.1377C11.7176 17.7372 12.2342 16.5914 13.0529 15.7002C12.9237 15.382 12.4713 14.0661 13.1823 12.3049C13.1823 12.3049 14.2809 11.9653 16.7579 13.6205C17.8184 13.34 18.9121 13.1973 20.0108 13.1961C21.1093 13.1961 22.2292 13.3448 23.2633 13.6205C25.7406 11.9653 26.8392 12.3049 26.8392 12.3049C27.5503 14.0661 27.0975 15.382 26.9683 15.7002C27.8086 16.5914 28.3039 17.7372 28.3039 19.1377C28.3039 24.0609 25.2668 25.1429 22.3586 25.4614C22.8327 25.8645 23.2417 26.6283 23.2417 27.8379C23.2417 29.5567 23.2204 30.9361 23.2204 31.3603C23.2204 31.6999 23.4575 32.1033 24.1035 31.9759C29.2733 30.278 32.9999 25.4825 32.9999 19.8169C33.0212 12.7291 27.1838 7 20.0108 7Z" fill="white" />
                                        </svg>
                                    </span>
                                </Nav.Link>
                                <Nav.Link href="https://app.fyda.fi" target='_blank' className='custom-button px-3 ms-3'>
                                    Launch dApp
                                    <svg className='ms-2 align-middle' width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L6.5 6.5L1 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Container>
            </Navbar>
        </header>
    )
}

export default Header
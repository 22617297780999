import React from 'react'
import Logo from "../../Assets/Images/logo-no-background.svg";
import Linkedin from "../../Assets/Images/linkedin.svg"
import Twitter from "../../Assets/Images/twitter.svg"
import Instagram from "../../Assets/Images/instagram.svg"
import Discord from "../../Assets/Images/discord.svg"
import Telegram from "../../Assets/Images/telegram.svg"
import "./Footer.scss";

const Footer = () => {
    return (
        <footer>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4 order-md-1 order-3'>
                        <div className='logo-div'>
                            <a href="/"><img src={Logo} alt="Logo" /></a>
                            <p>Where Smart Trade Automation meets Decentralized Execution.</p>
                            <ul className='ps-0 mb-0 d-flex'>
                                <li>
                                    <a href="https://www.linkedin.com/company/fyda-finance/" target='_blank'><img src={Linkedin} alt="Linkedin" /></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/fydafinance" target='_blank'><img src={Twitter} alt="Twitter" /></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/fydafinance/" target='_blank'><img src={Instagram} alt="Instagram" /></a>
                                </li>
                                <li>
                                    <a href="https://t.me/+aIDx23b8b2g1ODE9" target='_blank'><img src={Telegram} alt="Instagram" /></a>
                                </li>
                                <li>
                                    <a href="https://discord.gg/Bn7Ys4wgJ2" target='_blank'><img src={Discord} alt="Instagram" /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-4 order-md-2 order-2'>
                        <div className="footer-content d-md-flex justify-content-between">
                            <ul className='ps-0 mb-md-0'>
                                <li>
                                    <h4>Get Started</h4>
                                </li>
                                <li>
                                    <a target='_blank' href="https://docs.fyda.fi">
                                        Docs
                                    </a>
                                </li>
                                <li>
                                    <a target='_blank' href="https://fyda-fi.medium.com/">
                                        Blogs
                                    </a>
                                </li>
                                <li>
                                    <a target='_blank' href="https://simulation.fyda.fi">
                                        Simulation
                                    </a>
                                </li>
                            </ul>
                            <ul className='ps-0 mb-md-0'>
                                <li>
                                    <h4>Resources</h4>
                                </li>
                                <li>
                                    <a target='_blank' href="https://drive.google.com/file/d/1p2_GB-xfbhxdzY1M3WLDrrsNOtgVd4ku/view">
                                        Whitepaper
                                    </a>
                                </li>
                                <li>
                                    <a href="https://docs.fyda.fi/tokenomics" target='_blank'>
                                        Tokenomics
                                    </a>
                                </li>
                                <li>
                                    <a href="https://github.com/Fyda-Finance/protocol/tree/main/audits" target='_blank'>
                                        Audit Reports
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-4 order-md-3 order-1'>
                        <div className='footer-content mb-md-0 mb-4'>
                            <h3>NEWSLETTER</h3>
                            <p>Subscribe our newsletter to get regular updates about our products and roadmap</p>
                            <button className='custom-gradient-btn' onClick={() => {
                                window.open("https://mailchi.mp/76a819be6bb9/fydafi-newsletter")
                            }}>Subscribe Now
                                <svg className="ms-4" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="32" height="32" rx="16" fill="currentColor" fill-opacity="0.2" />
                                    <path d="M13 10L18.5 15.5L13 21" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className='col-12 order-md-4 order-4'>
                        <div className='copyright-div text-center pt-3 mt-3'>
                            <p className='mb-0'>Copyright © 2023 Byzantine Labs Ltd. All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
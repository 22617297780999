import React from 'react'

const WorkItems = [
    {
        id: 1,
        desc: "Choose a network, select a pair of tokens, and input the trade values.",
        title: "Build",
        image: "card-img-1.svg",
        numberImg: "1-img.svg"
    },
    {
        id: 2,
        desc: "Evaluate strategies through historical simulations and test them in real-time live simulation.",
        title: "Simulate",
        image: "card-img-2.svg",
        numberImg: "2-img.svg"
    },
    {
        id: 3,
        desc: "Put strategies into action on the live network and monitor the portfolio.",
        title: "Launch",
        image: "card-img-3.svg",
        numberImg: "3-img.svg"
    },
]

const HowItWorks = () => {
    return (
        <section className='how-it-works-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='heading-title mb-5'>
                            <h2>How it works</h2>
                            <p>With just 3 steps users can create strategies to invest and trade.</p>
                        </div>
                        <div className='position-relative outer-works-container'>
                            <ul className='ps-0 d-flex flex-wrap justify-content-center align-items-center'>
                                {WorkItems.map((items, key) => (
                                    <li key={key.id}>
                                        <div className='card-outer'>
                                            <img src={"../../Assets/" + `${items.numberImg}`} alt="NumberIcon" className='number-icon' />
                                            <div className='card-inner text-center'>
                                                <div className='card-work-header d-md-block d-flex align-items-center'>
                                                    <img src={"../../Assets/" + `${items.image}`} alt="Icon" className='me-md-0 me-3 mb-2' />
                                                    <h3>{items.title}</h3>
                                                </div>
                                                <p className='text-md-center mx-md-auto mb-0'>{items.desc}</p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWorks
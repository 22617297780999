import React from 'react'
import Header from '../../Components/Header/Header'
import WhatWeDo from '../../Components/Home/WhatWeDo'
import Banner from '../../Components/Home/Banner'
import Building from '../../Components/Home/Building'
import HowItWorks from '../../Components/Home/HowItWorks'
import KeyFeatures from '../../Components/Home/KeyFeatures'
import Footer from '../../Components/Footer/Footer'
import Community from '../../Components/Home/Community'
import Partners from '../../Components/Home/Partners'
import PartnerLogo from '../../Components/Home/PartnerLogo'
import TopBanner from '../../Components/Header/TopBanner'
const Home = () => {
    return (
        <>
            {/* <TopBanner /> */}
            <Header></Header>
            <Banner />
            <Partners />
            <WhatWeDo />
            <PartnerLogo />
            <HowItWorks />
            <Building />
            <KeyFeatures />
            <Community />
            <Footer />
        </>
    )
}

export default Home

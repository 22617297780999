import React from 'react'
import Header from '../../Components/Header/Header'
import Bannertokenomics from '../../Components/Tokenomics/Banner'
import TokenDistribution from '../../Components/Tokenomics/TokenDistribution'
import Faq from '../../Components/Tokenomics/Faq'
import Footer from '../../Components/Footer/Footer'
import Roadmap from '../../Components/Tokenomics/Roadmap'
import Benefits from '../../Components/Tokenomics/Benefits'
import Team from '../../Components/Tokenomics/Team'
import Marketing from '../../Components/Tokenomics/Marketing'
import TokenUtilities from '../../Components/Tokenomics/TokenUtilities'
// import Reviews from '../../Components/Tokenomics/Reviews'

const Tokenomics = () => {
    return (
        <>
            <Header></Header>
            <Bannertokenomics />
            <Benefits />
            <Marketing />
            {/* <Reviews /> */}
            <TokenDistribution />
            <TokenUtilities />
            <Roadmap />
            {/* <Team /> */}
            <Faq />
            <Footer />
        </>
    )
}

export default Tokenomics
import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FeatureImg from "../../Assets/Images/feature-img.svg";

const FeaturesItems = [

    {
        id: 6,
        title: "Revenue Sharing",
        desc: "A fixed percentage of all the revenue generated by the protocol is distributed among $FYDA holders, fostering revenue sharing within the ecosystem.",
        img: "feature-1.svg"
    },
    {
        id: 6,
        title: "Token Burning",
        desc: "A percentage of the daily revenues from Fyda is going to be used to purchase $FYDA tokens from the open market and burn, thus reducing the supply while the demand increases.",
        img: "feature-2.svg"
    },
    {
        id: 1,
        title: "Proposals and Voting Rights",
        desc: "$FYDA holders can propose and vote on proposals within the DAO thus ensuring decentralization of Fyda protocol.",
        img: "feature-3.svg"
    },
    {
        id: 1,
        title: "Staking Rewards",
        desc: "Fyda will deploy a vault where users can stake FYDA tokens and be eligible of staking and revenue sharing rewards.",
        img: "feature-4.svg"
    },
    {
        id: 3,
        title: "Safety Module Staking",
        desc: "Stake $FYDA in Fyda safety module to enhance security and earn higher rewards. Up to 30 percent of the stake can cover losses during attacks, adding protection to the protocol. ",
        img: "feature-1.svg"
    },
    {
        id: 2,
        title: "Fyda Nodes",
        desc: "Staking $FYDA allows participation in the Fyda signals network, responsible for providing smart signals to the protocol. Running $FYDA nodes will be rewarded with FYDA tokens.",
        img: "feature-2.svg"
    },

    {
        id: 4,
        title: "Data Access",
        desc: "Other contracts need to pay in $FYDA to access smart signals data from Fyda protocol.",
        img: "feature-3.svg"
    },

    {
        id: 5,
        title: "Copy Strategies",
        desc: "Payment in $FYDA is required to copy strategies from experienced traders.",
        img: "feature-4.svg"
    },
]
function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.455948 0.498306C1.00919 -0.0549367 1.90617 -0.0549367 2.45942 0.498306L10.9594 8.99831C11.5127 9.55155 11.5127 10.4485 10.9594 11.0018L2.45942 19.5018C1.90617 20.055 1.00919 20.055 0.455948 19.5018C-0.0972951 18.9485 -0.0972951 18.0515 0.455948 17.4983L7.95421 10L0.455948 2.50178C-0.0972951 1.94853 -0.0972951 1.05155 0.455948 0.498306Z" fill="white" />
            </svg>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5441 0.498306C10.9908 -0.0549367 10.0938 -0.0549367 9.54058 0.498306L1.04058 8.99831C0.487341 9.55155 0.487341 10.4485 1.04058 11.0018L9.54058 19.5018C10.0938 20.055 10.9908 20.055 11.5441 19.5018C12.0973 18.9485 12.0973 18.0515 11.5441 17.4983L4.04579 10L11.5441 2.50178C12.0973 1.94853 12.0973 1.05155 11.5441 0.498306Z" fill="white" />
            </svg>
        </div>
    );
}
const TokenUtilities = () => {
    var settings = {
        dots: true,
        infinite: false,
        rows: 2,
        padding: 20,
        slidesToShow: 2,
        slidesToScroll: 2,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    rows: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    rows: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };
    return (
        <section className='key-features-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-xl-3 col-lg-4 header'>
                        <div className='heading-title text-left'>
                            <h2>Token Utilities</h2>
                            <svg class="mt-2 d-block" width="213" height="14" viewBox="0 0 213 14" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.4" width="183.21" height="14" rx="7" fill="orange"></rect><rect opacity="0.4" x="198.209" width="14" height="14" rx="7" fill="yellow"></rect><defs><linearGradient id="paint0_linear_2587_1255" x1="178.5" y1="6.99997" x2="9.50001" y2="2.5" gradientUnits="userSpaceOnUse"><stop stop-color="white"></stop><stop offset="1" stop-color="white" stop-opacity="0"></stop></linearGradient></defs>
                            </svg>
                        </div>
                    </div>
                    <div className='col-xl-9 col-lg-8'>
                        <Slider {...settings} className="">
                            {FeaturesItems.map((items, key) => (
                                <div className='feature-slider' key={key.id}>
                                    <div className='feature-outer-div mb-4'>
                                        <h3 className='d-flex align-items-center'><img src={"../../Assets/" + `${items.img}`} alt="Icon" className='me-3' />{items.title}</h3>
                                        <p>{items.desc}</p>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TokenUtilities
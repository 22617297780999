import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';

const Faq = () => {

    return (
        <>
            <section className='faq-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div class="heading-title mb-5 text-center">
                                <h2>FAQ</h2>
                            </div>
                        </div>
                        <div className='col-md-10 mx-auto'>

                            <Accordion flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What is Fyda Finance?</Accordion.Header>
                                    <Accordion.Body>
                                    Fyda Finance is a non-custodial decentralized protocol for automating investments and trading strategies. With Fyda, users can automate their buy, sell, and floor prices, all via smart contracts, using a single configuration before executing their trade. 
                                    <br></br>     <br></br>
                                    Additionally, Fyda enables users to test trading strategies using historical and real-time data in simulation mode. It supports various trading approaches, from basic to advanced, to help users automate trades and improve their trading performance.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>What makes Fyda unique?</Accordion.Header>
                                    <Accordion.Body>
                                    Fyda allows users to automate their trades using trading signals, chart pattern recognitions and AI signals. On top of it Fyda, allows users to automate advanced DCA features such as Buy the Dip, Buy the Spike, Sell the Spike, Trading Ranges, Dynamic Percent Orders and so on. 
                                    <br></br>     <br></br>
                                    Fyda also provides a simulation engine where you can simulate trading strategies with historical or live prices or signals for experimentation, learning and sharpening trading skills. 
                                    <br></br>     <br></br>
                                    This is what makes Fyda different from other traditional DEXs. Any newcomer trader can utilise the most advanced trading techniques in the simplest and secure way. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>How and where can I buy FYDA?</Accordion.Header>
                                    <Accordion.Body>
                                    As of now, Fyda Finance (FYDA) is in its presale phase, which means it is not yet available on traditional cryptocurrency exchanges or DeFi platforms like Uniswap. 
                                    <br></br>     <br></br>
                                    Beware of potential scams using Fyda's name. The only legitimate way to participate in the Fyda presale is through our official website at <a href="https://app.fyda.fi/pre-sale" target='_blank'>app.fyda.fi/pre-sale</a> . Please exercise caution and ensure you're on the official platform to avoid fraudulent schemes. 
                                    <br></br>     <br></br>
                                    $FYDA can be purchased using BNB, ETH, USDT (ERC-20) and USDC (ERC-20) on Ethereum, BNB Chain, Polygon, Arbitrum and Optimism Chains. 
                                    <br></br>     <br></br>
                                    If you want to buy $FYDA via card, you can purchase BNB or ETH using your card, then buy $FYDA. Ensure you have enough BNB or ETH to cover the gas fees. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>When is the token generation event (TGE) going to happen?</Accordion.Header>
                                    <Accordion.Body>
                                    The team expects the presale to sell out and close by the 30th April 2024. The public sale (IDO) will happen in May 2024. The creation of the liquidity pool will be done 2 days after the public sale. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>When will I receive my FYDA tokens?</Accordion.Header>
                                    <Accordion.Body>
                                    You will receive your tokens in your wallet via an airdrop once the TGE has concluded. You will be able to view and keep track of your holdings during the presale at <a href="https://app.fyda.fi/pre-sale" target='_blank'>app.fyda.fi/pre-sale</a>.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Will you list on an exchange?</Accordion.Header>
                                    <Accordion.Body>
                                    We will list on Uniswap, and we will also be listing on several Centralized Exchanges (CEXs).
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>How do I know the team won't dump the tokens?</Accordion.Header>
                                    <Accordion.Body>
                                    All team tokens have a cliff of 6 months and 12 months linear vesting from the TGE. The team will have zero access to $FYDA and any unsold presale tokens will be burnt.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default Faq
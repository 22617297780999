import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Tokenomics from './Pages/Tokenomics/Tokenomics';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/pre-sale" element={<Tokenomics />}></Route>
      </Routes>
    </div >
  );
}

export default App;

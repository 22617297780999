import React from 'react'
import MarketingImg from "../../Assets/Images/marketing-svg.svg"

const Marketing = () => {
    return (
        <section className='marketing-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='heading-title mb-md-5 mb-3'>
                            <h2>Vision and Market Opportunity</h2>
                        </div>
                    </div>
                    <div className='col-md-12 col-xxl-9 mx-auto'>
                        <div className='row align-items-center'>
                            <div className='col-md-6'>
                                <img src={MarketingImg} alt="MarketingImage" className='pe-md-4' />
                            </div>
                            <div className='col-md-6'>
                                <div className='marketing-content mt-md-0 mt-4'>
                                    <p>Fyda pioneers the most sophisticated decentralized automated trading platform allowing traders to liberate themselves from constant market monitoring and execute intricate trading strategies effortlessly.
                                    </p>
                                    <p>With the estimated daily trading volume in the crypto market exceeds $100 billion, a notable trend is the increasing dominance of DEX trading volume, accounting for over 10% and anticipated to command more than 60% of the market in the next decade. </p>
                                    <p>It's evident that an automated trading platform for DEX traders will streamline the trading processes, bringing about a significant transformation in the decentralized trading landscape.</p>
                                </div>
                                <div className='button-div d-md-flex align-items-center mt-3'>
                                    <a href="https://fyda-fi.medium.com/introduction-to-decentralized-automated-trading-dfa103b5d1b7" target='_blank' className='custom-button justify-content-md-start justify-content-center d-flex align-items-center me-md-3 text-decoration-none'>
                                        <svg className='me-2' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.0424 1.00005C23.0076 1.01504 27.0149 5.07942 26.99 10.0734C26.965 15.0074 22.9064 19.013 17.9512 18.9943C16.7679 18.9872 15.5976 18.7468 14.5073 18.2868C13.417 17.8267 12.4281 17.1561 11.5971 16.3132C10.7662 15.4703 10.1095 14.4718 9.66467 13.3747C9.21986 12.2776 8.99565 11.1036 9.00486 9.9197C9.02484 4.99072 13.0847 0.983807 18.0424 1.00005ZM16.9484 12.9945C17.3418 13.0145 17.609 12.7909 17.865 12.5347C19.0264 11.3686 20.1895 10.2062 21.3542 9.0476C22.4419 7.96185 22.4319 7.97184 21.3154 6.92357C21.047 6.67369 20.9033 6.70118 20.6661 6.94482C19.7657 7.86939 18.8466 8.77522 17.935 9.69355C16.9996 10.6281 16.9996 10.6281 16.0618 9.69355C14.9379 8.56907 14.9491 8.58031 13.8652 9.73603C13.6641 9.95093 13.6391 10.0721 13.8577 10.287C14.6594 11.0742 15.4436 11.88 16.2304 12.6772C16.3226 12.7754 16.4338 12.8539 16.5572 12.908C16.6806 12.9621 16.8137 12.9907 16.9484 12.992V12.9945Z" fill="currentColor" />
                                            <path d="M6.97558 30.9888C5.85166 30.9888 4.72775 30.9975 3.60384 30.9888C2.07157 30.975 1.02509 29.9267 1.00886 28.3974C0.995118 27.148 0.937671 25.8986 1.45842 24.7166C1.73513 24.089 2.10199 23.5052 2.54737 22.9837C2.79713 22.6901 2.94323 22.6988 3.23295 22.9612C4.92257 24.4942 6.85444 24.8828 9.0111 24.1019C9.6975 23.8519 10.3171 23.447 10.8219 22.9187C11.0591 22.6688 11.204 22.7051 11.4175 22.94C12.317 23.908 12.8643 25.1509 12.971 26.4683C13.0302 27.2167 13.0335 27.9684 12.981 28.7173C12.8973 29.9967 11.8359 30.9663 10.5234 30.99C9.34454 31.005 8.15943 30.9888 6.97558 30.9888Z" fill="currentColor" />
                                            <path d="M7.0018 15.4946C7.69379 15.4946 8.37022 15.7001 8.94545 16.0849C9.52068 16.4698 9.96884 17.0167 10.2332 17.6566C10.4976 18.2964 10.5662 19.0003 10.4305 19.6792C10.2948 20.3581 9.96074 20.9814 9.47073 21.4703C8.98072 21.9592 8.35675 22.2916 7.67782 22.4254C6.99888 22.5593 6.29552 22.4886 5.65677 22.2222C5.01802 21.9559 4.47262 21.506 4.08961 20.9293C3.7066 20.3527 3.50321 19.6754 3.50519 18.983C3.50783 18.0569 3.87739 17.1697 4.53285 16.5157C5.18831 15.8618 6.07617 15.4946 7.0018 15.4946Z" fill="currentColor" />
                                            <path d="M23.055 28.9859C25.5101 28.9859 27.9653 29.0009 30.4229 28.9747C30.9062 28.9684 31.0048 29.1159 30.9998 29.5694C30.9798 30.9888 30.9998 30.99 29.5762 30.99C24.9369 30.99 20.2977 30.9825 15.6584 31C15.1751 31 14.9678 30.9363 14.9841 30.3753C15.024 28.9872 14.9928 28.9859 16.3815 28.9859H23.055Z" fill="currentColor" />
                                            <path d="M30.9886 20.6098C30.9249 21.5269 30.4891 22.309 29.5475 22.7475C28.6059 23.1861 27.6743 23.0974 26.925 22.4177C25.9185 21.5169 25.0006 20.5186 24.0341 19.5728C23.8043 19.3479 23.9092 19.2454 24.1152 19.1055C25.2994 18.3228 26.3133 17.3088 27.0961 16.1243C27.2647 15.8745 27.3783 15.8282 27.6056 16.0606C28.5085 16.9827 29.4363 17.881 30.333 18.8094C30.78 19.2729 30.9836 19.8601 30.9886 20.6098Z" fill="currentColor" />
                                            <path d="M19.5584 24.9878C20.8684 24.9878 22.1809 25.0053 23.4896 24.9778C23.9104 24.969 24.0016 25.1127 24.0016 25.5075C24.0016 26.9918 24.0178 26.9918 22.5193 26.9918C20.2103 26.9918 17.8988 26.9818 15.5935 27.0006C15.1664 27.0006 14.9691 26.9494 14.9828 26.4408C15.0066 24.989 14.9828 24.9878 16.4389 24.9878H19.5584Z" fill="currentColor" />
                                        </svg>
                                        Market Research</a>
                                    <a href="https://drive.google.com/file/d/183fTLecOEMP3WT1C56DCbOuLwDkAPQDR/view#" target='_blank ' className='custom-button justify-content-md-start justify-content-center d-flex align-items-center me-md-3 ghost text-decoration-none'>

                                        Pitch Deck
                                        <svg className='ms-2' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.2761 16.1033C12.8798 14.7028 11.6352 13.4562 10.3882 12.2061C9.40845 11.2267 8.41903 10.2581 7.45008 9.26904C6.86871 8.67465 6.86028 7.92264 7.3911 7.39202C7.92192 6.8614 8.68143 6.8602 9.27002 7.44496C11.8049 9.96209 14.3326 12.4868 16.8531 15.0192C17.4754 15.6449 17.473 16.3536 16.8459 16.9829C14.3407 19.5016 11.8282 22.0123 9.30854 24.515C8.68504 25.1346 7.92673 25.1551 7.38267 24.6076C6.83862 24.0602 6.87112 23.3166 7.50304 22.6825C9.5573 20.6226 11.6184 18.5683 13.6863 16.5196C13.8718 16.3658 14.069 16.2266 14.2761 16.1033Z" fill="currentColor" />
                                            <path d="M21.8905 16.0335C19.6637 13.7979 17.5043 11.6454 15.3702 9.46637C15.0989 9.18266 14.9092 8.83097 14.8213 8.44845C14.7106 7.93106 15.0043 7.51355 15.4593 7.25726C15.667 7.13038 15.9086 7.07019 16.1516 7.08482C16.3946 7.09945 16.6272 7.18819 16.8182 7.33908C16.9607 7.44824 17.094 7.56897 17.2166 7.70005C19.6023 10.0848 21.9868 12.4692 24.37 14.8532C25.2054 15.6882 25.2126 16.3319 24.376 17.1645C21.9494 19.591 19.5217 22.0163 17.0927 24.4404C16.5209 25.0107 15.9829 25.1346 15.4196 24.8483C14.6107 24.4356 14.4795 23.4285 15.1788 22.7222C16.4595 21.4215 17.7607 20.1389 19.0546 18.8502C19.9887 17.919 20.9263 16.9889 21.8905 16.0335Z" fill="currentColor" />
                                        </svg></a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Marketing
import React from 'react'

const BenefitsItems = [
    {
        id: 1,
        desc: "Similar projects with far inferior offerings share a token FDMC of $50-100M at bare minimum, where $FYDA is designed with only $10M FDMC at listing. ",
        title: "10-100x Potential",
        image: "benefit-1.svg",
        button: "$FYDA Sale Metrics",
        link: "https://docs.google.com/spreadsheets/d/1RUJStZan9dh572VuInqLLlswKGzpZdbFOrLp_ER0ztw/edit#gid=555679887"
    },
    {
        id: 2,
        desc: "Five pre sale investors will receive $40k each through a raffle. $100k will be distributed to community participants. Boost your chances by buying more tokens.",
        title: "$300k Giveaway",
        image: "benefit-2.svg",
        button: "Win Prizes",
        link: "https://zealy.io/c/fydafinance/questboard/c9b0303a-f606-4b79-84e1-f212c405713c"
    },
    {
        id: 3,
        desc: "$FYDA holders receive 50% share of the platform's revenue and rewards, including 15-20% staking APY. Addtionally holders get discounts on trading fees.",
        title: "Passive Income",
        image: "benefit-3.svg",
        button: "$FYDA Tokenomics",
        link: "https://docs.fyda.fi/tokenomics"
    },
]

const Benefits = () => {
    return (
        <section className='how-it-works-section benefit-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='heading-title mb-md-5 mb-3'>
                            <h2>Benefits of Participation</h2>
                        </div>
                        <div className='position-relative outer-works-container'>
                            <ul className='ps-0 d-flex flex-wrap justify-content-center align-items-center'>
                                {BenefitsItems.map((items, key) => (
                                    <li key={key.id}>
                                        <div className='card-outer'>
                                            <div className='card-inner text-center'>
                                                <div className='card-work-header d-block'>
                                                    <img src={"../../Assets/" + `${items.image}`} alt="Icon" className='mb-2' />
                                                    <h3>{items.title}</h3>
                                                </div>
                                                <p className='text-md-center mx-md-auto mb-0'>{items.desc}</p>
                                                <a href={items.link} target='_blank' className='custom-button d-block mt-5 w-100 text-decoration-none'>{items.button}</a>
                                            </div >
                                        </div >
                                    </li >
                                ))}
                            </ul >
                        </div >
                    </div >
                </div >
            </div >
        </section >
    )
}

export default Benefits
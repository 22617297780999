import React from 'react'


const IconItem = [
    {
        id: 1,
        icon: "img-icon-1.svg"
    },
    {
        id: 2,
        icon: "img-icon-2.svg"
    },
    {
        id: 3,
        icon: "img-icon-3.svg"
    },
    {
        id: 4,
        icon: "img-icon-4.svg"
    },
    {
        id: 5,
        icon: "img-icon-5.svg"
    },
    {
        id: 6,
        icon: "img-icon-6.svg"
    },
    {
        id: 7,
        icon: "img-icon-7.svg"
    },
    {
        id: 8,
        icon: "img-icon-8.svg"
    },
    {
        id: 9,
        icon: "img-icon-9.svg"
    },
    {
        id: 10,
        icon: "img-icon-10.svg"
    },
    {
        id: 11,
        icon: "img-icon-11.svg"
    },
]
const Building = () => {
    return (
        <section className='building-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-1'></div>
                    <div className='col-md-10'>
                        <div className='heading-title'>
                            <h2>Networks</h2>
                        </div>

                        <div className='rounded-section'>
                            <ul className='text-center ps-0 mb-0'>
                                {IconItem.map((items, key) => (
                                    <li key={key.id} className='text-center'>
                                        <div className='outer-box d-flex align-items-center justify-content-center'>
                                            <img src={"../../Assets/" + `${items.icon}`} alt="Icon" />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-1'></div>
                </div>
            </div>
        </section>
    )
}

export default Building
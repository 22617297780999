import React from 'react'
import Tagline from "../../Assets/Images/fyda-tagline.svg";

// const DataValues = [
//     {
//         id: 1,
//         title: "Number of Giveaways",
//         value: "+ $10,000",
//         textValue: "Dollars",
//         image: "icon-1.svg"
//     },
//     {
//         id: 2,
//         title: "Discord Community",
//         value: "+ 1000",
//         textValue: "Total Member (N)",
//         image: "icon-2.svg"
//     },
//     {
//         id: 3,
//         title: "Certified Fyda user",
//         value: "+ $5,000",
//         textValue: "Dollars",
//         image: "icon-3.svg"
//     },
//     {
//         id: 4,
//         title: "Total Fyda users",
//         value: "+ $12,600.10",
//         textValue: "Users",
//         image: "icon-4.svg"
//     },
// ]

const Bannertokenomics = () => {
    return (
        <section className='token-banner d-flex align-items-center'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8 mx-auto'>
                        <div className='banner-content text-center'>
                            <p className='tagline-content mx-auto d-inline-block'><span>1 FYDA = $0.005</span> Next Phase Price: 1 FYDA = $0.006</p>
                            <h1>$FYDA Pre-Sale is Live</h1>
                            <p>FYDA token is designed to power the entire Fyda platform and allocation has been intelligently designed.</p>
                            <div className='d-flex flex-wrap button-div justify-content-center'>
                                <a href="https://app.fyda.fi/pre-sale" className='custom-btn text-decoration-none d-flex align-items-center'>
                                    <svg className='me-2' width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M25.1988 9.86348C24.6767 7.17638 22.722 6 20.0023 6H9.3422C6.13688 6 4 7.63455 4 11.4485V17.8257C4 20.5747 5.10486 22.1845 6.92607 22.878C7.19318 22.977 7.48457 23.0637 7.7881 23.1132C8.27376 23.2247 8.79583 23.2742 9.3422 23.2742H20.0144C23.2198 23.2742 25.3566 21.6397 25.3566 17.8257V11.4485C25.3566 10.8665 25.3081 10.3464 25.1988 9.86348ZM8.638 16.5007C8.638 17.0084 8.22519 17.4295 7.7274 17.4295C7.2296 17.4295 6.81679 17.0084 6.81679 16.5007V12.7859C6.81679 12.2782 7.2296 11.8571 7.7274 11.8571C8.22519 11.8571 8.638 12.2782 8.638 12.7859V16.5007ZM14.6723 17.9124C12.8996 17.9124 11.4669 16.4512 11.4669 14.6433C11.4669 12.8354 12.8996 11.3742 14.6723 11.3742C16.4449 11.3742 17.8776 12.8354 17.8776 14.6433C17.8776 16.4512 16.4449 17.9124 14.6723 17.9124ZM22.5156 16.5007C22.5156 17.0084 22.1028 17.4295 21.605 17.4295C21.1072 17.4295 20.6944 17.0084 20.6944 16.5007V12.7859C20.6944 12.2782 21.1072 11.8571 21.605 11.8571C22.1028 11.8571 22.5156 12.2782 22.5156 12.7859V16.5007Z" fill="currentColor" />
                                        <path d="M29 15.1619V21.5391C29 25.3531 26.8631 27 23.6457 27H12.9856C12.075 27 11.2615 26.8638 10.5573 26.5914C9.98664 26.3809 9.48884 26.0713 9.08818 25.675C8.86964 25.4645 9.03961 25.1302 9.34315 25.1302H20.0033C24.4956 25.1302 27.1667 22.4059 27.1667 17.8366V11.447C27.1667 11.1498 27.4945 10.9641 27.7009 11.187C28.5265 12.0785 29 13.3787 29 15.1619Z" fill="currentColor" />
                                    </svg>
                                    Buy $FYDA</a>
                                <a href="https://drive.google.com/file/d/1p2_GB-xfbhxdzY1M3WLDrrsNOtgVd4ku/view" target='_blank' className='custom-btn ghost text-decoration-none d-flex align-items-center'>
                                    <svg className='me-2' width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.9009 14.5492L25.4909 24.4392C25.5109 24.7092 25.3809 24.8892 25.3109 24.9692C25.2309 25.0592 25.0609 25.1992 24.7809 25.1992H22.0509L24.2109 14.5492H24.9009ZM26.0009 10.6992L25.9909 10.7192C26.0109 10.9592 25.9909 11.2092 25.9309 11.4592L18.5609 24.9892C18.3209 25.9992 17.4209 26.6992 16.3809 26.6992H24.7809C26.0709 26.6992 27.0909 25.6092 26.9909 24.3192L26.0009 10.6992Z" fill="currentColor" />
                                        <path d="M15.4502 6.94058C15.5502 6.54058 15.3002 6.13058 14.9002 6.03058C14.5002 5.94058 14.0902 6.18058 13.9902 6.58058L13.4902 8.65058H15.0302L15.4502 6.94058Z" fill="currentColor" />
                                        <path d="M22.0509 6.90781C22.1409 6.49781 21.8809 6.10781 21.4709 6.01781C21.0709 5.92781 20.6709 6.18781 20.5809 6.59781L20.1309 8.66781H21.6709L22.0509 6.90781Z" fill="currentColor" />
                                        <path d="M25.8198 10.0306C25.4898 9.23062 24.7098 8.66062 23.7498 8.66062H21.6698L21.1098 11.2506C21.0298 11.6006 20.7198 11.8406 20.3798 11.8406C20.3298 11.8406 20.2698 11.8406 20.2198 11.8206C19.8198 11.7306 19.5598 11.3306 19.6398 10.9306L20.1298 8.65062L15.0302 8.65058L14.3998 11.2506C14.3198 11.5906 14.0098 11.8206 13.6698 11.8206C13.6098 11.8206 13.5498 11.8106 13.4898 11.8006C13.0898 11.7006 12.8398 11.3006 12.9398 10.8906L13.4798 8.64062H11.4498C10.4698 8.64062 9.59975 9.28062 9.30975 10.2206L5.09975 23.7706C4.65975 25.2206 5.72975 26.7006 7.23975 26.7006H20.3798C21.4198 26.7006 22.3198 26.0006 22.5598 24.9906L25.9298 11.4606C25.9898 11.2106 26.0098 10.9606 25.9898 10.7206C25.9698 10.4806 25.9198 10.2406 25.8198 10.0306ZM18.6998 21.4506H10.6998C10.2898 21.4506 9.94975 21.1106 9.94975 20.7006C9.94975 20.2906 10.2898 19.9506 10.6998 19.9506H18.6998C19.1098 19.9506 19.4498 20.2906 19.4498 20.7006C19.4498 21.1106 19.1098 21.4506 18.6998 21.4506ZM19.6998 17.4506H11.6998C11.2898 17.4506 10.9498 17.1106 10.9498 16.7006C10.9498 16.2906 11.2898 15.9506 11.6998 15.9506H19.6998C20.1098 15.9506 20.4498 16.2906 20.4498 16.7006C20.4498 17.1106 20.1098 17.4506 19.6998 17.4506Z" fill="currentColor" />
                                    </svg>
                                    Whitepaper</a>
                            </div>
                        </div>
                        {/* <ul className='d-flex ps-0 token-listing flex-wrap justify-content-center'>
                            {DataValues.map((items, key) => (
                                <li key={key.id}>
                                    <div className='outer-div position-relative'>
                                        <img src={"../../Assets/" + `${items.image}`} alt="Icon" className='position-absolute' />
                                        <p>{items.value}</p>
                                        <span>{items.textValue}</span>
                                        <h2>{items.title}</h2>
                                    </div>
                                </li>
                            ))}
                        </ul> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Bannertokenomics

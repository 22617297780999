import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Partner2 from "../../Assets/Images/partner-3.png"
import Partner3 from "../../Assets/Images/partner-2.png"

const partnerItems = [
    {
        id: 1,
        title: "About",
        desc: "0x is an exchange aggregator that scans decentralized exchanges to find the lowest cryptocurrency prices for traders",
        title2: "Role",
        desc2: "Fyda bot utilises 0x APIs to find the best prices for traders to execute their trades across networks",
        img: "partner-new-1.svg"
    },
    {
        id: 2,
        title: "About",
        desc: "Alchemy is a cloud platform that provides seemless access to different blockchain networks and data",
        title2: "Role",
        desc2: "Fyda bot utilises Alchemy APIs to interact with different blockchain networks to monitor and execute trades",
        img: "partner-new-5.svg"
        
    },
    {
        id: 3,
        title: "About",
        desc: "SolidProof is a leading smart contract auditor who analyze and monitor blockchain protocols and DeFi projects",
        title2: "Role",
        desc2: "Fyda protocol smart contracts are audited by SolidProof to ensure security and safety of user funds",
        img: "partner-new-3.svg"
    },
    {
        id: 4,
        title: "About",
        desc: "Coinmarketcap is a cryptocurrency data aggregator that provides accurate and reliable data to users",
        title2: "Role",
        desc2: "Fyda simulation engine utilises Coinmarketcap APIs to fetch price data for backtesting and simulation",
        img: "partner-new-4.svg"
    },
    {
        id: 5,
        title: "About",
        desc: "Chainlink is a decentralized blockchain oracle network that proivides secure and verified data feeds to smart contracts",
        title2: "Role",
        desc2: "Fyda protocol utilises Chainlink feeds to fetch price from trusted sources to protect users from price manipulation",
        img: "partner-new-2.svg"
    },
    {
        id: 6,
        title: "About",
        desc: "Moralis is a blockchain platform that provides a suite of tools for developers to build and scale their dApps",
        title2: "Role",
        desc2: "Fyda utilises Moralis to index and query blockchain data to monitor transactions and detect attacks on protocol",
        img: "partner-new-6.svg"
    },
]

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.455948 0.498306C1.00919 -0.0549367 1.90617 -0.0549367 2.45942 0.498306L10.9594 8.99831C11.5127 9.55155 11.5127 10.4485 10.9594 11.0018L2.45942 19.5018C1.90617 20.055 1.00919 20.055 0.455948 19.5018C-0.0972951 18.9485 -0.0972951 18.0515 0.455948 17.4983L7.95421 10L0.455948 2.50178C-0.0972951 1.94853 -0.0972951 1.05155 0.455948 0.498306Z" fill="white" />
            </svg>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5441 0.498306C10.9908 -0.0549367 10.0938 -0.0549367 9.54058 0.498306L1.04058 8.99831C0.487341 9.55155 0.487341 10.4485 1.04058 11.0018L9.54058 19.5018C10.0938 20.055 10.9908 20.055 11.5441 19.5018C12.0973 18.9485 12.0973 18.0515 11.5441 17.4983L4.04579 10L11.5441 2.50178C12.0973 1.94853 12.0973 1.05155 11.5441 0.498306Z" fill="white" />
            </svg>
        </div>
    );
}


const PartnerLogo = () => {
    var settings = {
        dots: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        rows: 2,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 1,
                }
            },
        ]
    };
    return (
        <section className='partner-logo'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='heading-title mb-md-5'>
                            <h2>Integration and Partners</h2>
                        </div>
                    </div>
                    <ul className='partner-listing ps-0 mb-0   w-100 mw-100'>
                        <Slider {...settings} className="">
                            {partnerItems.map((items, key) => (
                                <li key={key.id}>
                                    <div className='partner-outer'>
                                        <div className='partner-header'>
                                            <img src={"../../Assets/" + `${items.img}`} alt="Logo" />
                                        </div>
                                        <div className='partner-content'>
                                            <div className='content-inner mb-3'>
                                                <h3 className='mb-1 text-uppercase'>{items.title}</h3>
                                                <p className='mb-0'>{items.desc}</p>
                                            </div>
                                            <div className='content-inner'>
                                                <h3 className='mb-1 text-uppercaseF'>{items.title2}</h3>
                                                <p className='mb-0'>{items.desc2}</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </Slider>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default PartnerLogo
import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FeatureImg from "../../Assets/Images/feature-img.svg";

const FeaturesItems = [
    {
        id: 1,
        title: "Historical & Live Simulation",
        desc: "Historical Simulation assesses strategy performance in the past, while Live Simulation mirrors real-time prices, both elevate strategy creation.",
        img: "feature-1.svg"
    },
    {
        id: 3,
        title: "Trader's Cheat Sheet",
        desc: "Cheat Sheet offers traders pivotal Support/Resistance Levels, Key Turning Points, and other asset metrics for informed decisions.",
        img: "feature-3.svg"
    },
    {
        id: 2,
        title: "Protect and Repeat",
        desc: "Create recurring trades and implement stop-loss measures to safeguard against potential losses.",
        img: "feature-2.svg"
    },

    {
        id: 4,
        title: "Spread Orders",
        desc: "Set entry and exit buy/sell orders at different price levels and timeframes utilizing strategies like Buy the Dip, Buy the Spike, Sell the Rally, and TWAP.",
        img: "feature-5.svg"
    },

    {
        id: 5,
        title: "Telegram Notifications",
        desc: "You have the option to subscribe and receive notifications whenever your trades are executed, allowing you to liberate yourself from the need for constant monitoring.",
        img: "feature-2.svg"
    },
    {
        id: 6,
        title: "Gasless Trading",
        desc: "Engage in trading without the concern of having to replenish your account with native tokens for gas payments.",
        img: "feature-4.svg"
    },
]
function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.455948 0.498306C1.00919 -0.0549367 1.90617 -0.0549367 2.45942 0.498306L10.9594 8.99831C11.5127 9.55155 11.5127 10.4485 10.9594 11.0018L2.45942 19.5018C1.90617 20.055 1.00919 20.055 0.455948 19.5018C-0.0972951 18.9485 -0.0972951 18.0515 0.455948 17.4983L7.95421 10L0.455948 2.50178C-0.0972951 1.94853 -0.0972951 1.05155 0.455948 0.498306Z" fill="white" />
            </svg>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5441 0.498306C10.9908 -0.0549367 10.0938 -0.0549367 9.54058 0.498306L1.04058 8.99831C0.487341 9.55155 0.487341 10.4485 1.04058 11.0018L9.54058 19.5018C10.0938 20.055 10.9908 20.055 11.5441 19.5018C12.0973 18.9485 12.0973 18.0515 11.5441 17.4983L4.04579 10L11.5441 2.50178C12.0973 1.94853 12.0973 1.05155 11.5441 0.498306Z" fill="white" />
            </svg>
        </div>
    );
}
const KeyFeatures = () => {
    var settings = {
        dots: true,
        infinite: false,
        rows: 2,
        padding: 20,
        slidesToShow: 2,
        slidesToScroll: 2,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    rows: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    rows: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };
    return (
        <section className='key-features-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-xl-3 col-lg-4 header'>
                        <div className='heading-title text-left'>
                            <h2>Key Features</h2>
                            <svg class="mt-2 d-block" width="213" height="14" viewBox="0 0 213 14" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.4" width="183.21" height="14" rx="7" fill="orange"></rect><rect opacity="0.4" x="198.209" width="14" height="14" rx="7" fill="yellow"></rect><defs><linearGradient id="paint0_linear_2587_1255" x1="178.5" y1="6.99997" x2="9.50001" y2="2.5" gradientUnits="userSpaceOnUse"><stop stop-color="white"></stop><stop offset="1" stop-color="white" stop-opacity="0"></stop></linearGradient></defs>
                            </svg>
                        </div>
                    </div>
                    <div className='col-xl-9 col-lg-8'>
                        <Slider {...settings} className="">
                            {FeaturesItems.map((items, key) => (
                                <div className='feature-slider' key={key.id}>
                                    <div className='feature-outer-div mb-4'>
                                        <h3 className='d-flex align-items-center'><img src={"../../Assets/" + `${items.img}`} alt="Icon" className='me-3' />{items.title}</h3>
                                        <p>{items.desc}</p>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default KeyFeatures
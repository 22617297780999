import React from 'react'
import "./Home.scss"

const PartnerLogo = [
    {
        id: 7,
        image: "partner-7.svg",
        link: "https://coinmarketcap.com/community/articles/659baf1711f707656c1941c1/"
    },
    {
        id: 2,
        image: "partner-2.svg",
        link: "https://finance.yahoo.com/news/fyda-announces-launch-decentralized-trade-121900527.html"
    },
    {
        id: 3,
        image: "partner-9.svg",
        link: "https://apnews.com/press-release/globenewswire-mobile/fyda-announces-launch-of-decentralized-trade-automation-platform-3ff2545a89f49411439e222e0b4b8b72"
    },
    {
        id: 4,
        image: "partner-10.svg",
        link: "https://www.digitaljournal.com/pr/news/binary-news-network/fyda-s-breakthrough-defi-protocol-set-to-reshape-automated-trading"
    },
    {
        id: 5,
        image: "partner-5.svg",
        link: "https://www.tradingview.com/news/reuters.com,2024-01-08:newsml_GNX9RDkpt:0-fyda-announces-launch-of-decentralized-trade-automation-platform/"
    },
    {
        id: 6,
        image: "partner-6.svg",
        link: "https://markets.businessinsider.com/news/stocks/fyda-announces-launch-of-decentralized-trade-automation-platform-1032950030"
    },
    
    {
        id: 8,
        image: "partner-8.svg",
        link: "https://www.morningstar.com/news/globe-newswire/9014076/fyda-announces-launch-of-decentralized-trade-automation-platform"
    },
    {
        id: 1,
        image: "partner-1.svg",
        link: "https://cryptoadventure.com/fyda-revolutionizes-defi-with-mainnet-launch-redefining-the-decentralized-trading-experience"
    },
]

const Partners = () => {
    return (
        <section className='how-it-works-section partners-section pt-0'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='heading-title mb-5'>
                            <h2>Featured On</h2>
                            <p>We're featured in popular media outlets around the globe</p>
                        </div>
                        <div className='partners-container'>
                            <ul className='partner-listing d-flex align-items-center ps-0 mb-0 flex-wrap'>
                                {PartnerLogo.map((items, key) => (
                                    <li key={key.id}>
                                        <a href={items.link} target='_blank'>
                                            <div className='partner-container d-flex align-items-center justify-content-center'>
                                                <img src={"../../Assets/" + `${items.image}`} alt="PartnerLogo" />
                                            </div>
                                        </a>
                                    </li>
                                ))}

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Partners